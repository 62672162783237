<template>
  <div class="blog">
    <div
      v-for="(blogSection, index) in blogSections"
      :key="index"
      :class="`blog${index + 1}`"
    >
      <img
        :src="chooseImg(index)"
        alt=""
        loading="lazy"
        class="blog--image"
      >
      <div class="blog--inner">
        <h3>{{ t(`blogSections[${index}].title`) }}</h3>
        <p>{{ t(`blogSections[${index}].content`) }}</p>
        <ButtonBuyAnRv
          v-if="index === 1"
          :text-btn="t(`blogSections[${index}].buttonText`)"
          cta="popular-blogs-section"
          use-white
        />
        <ZButton
          v-else-if="index === 2"
          :to="blogSection.buttonTo"
          variant="white"
          outline
        >
          {{ blogSection.buttonText || t('seeMore') }}
        </ZButton>
        <ZButton
          v-else
          :to="blogSection.buttonTo"
          variant="white"
          outline
        >
          {{ blogSection.buttonText || t('seeMore') }}
        </ZButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
})

const localePath = useLocalePath()
const { isLoggedIn } = useAuthentication()
const blogSections = computed(() => [
  {
    buttonTo: localePath('blog'),
  },
  {
    buttonTo: localePath('rv-search'),
  },
  {
    buttonText: t('tellMeMore'),
    buttonTo: localePath(isLoggedIn.value ? 'dashboard-rvs-new' : 'owner'),
  },
])

const runtimeConfig = useRuntimeConfig()
const chooseImg = (index: number) => {
  if (index === 1) {
    return `${runtimeConfig.public.staticAssetsURL}/web/images/buy-an-rv/buy-an-rv-homepage-banner.webp`
  }
  return `${runtimeConfig.public.staticAssetsURL}/web-app/blog${index + 1}.webp`
}
</script>

<style lang="scss" scoped>
.blog {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'blog1'
    'blog2'
    'blog3';

  @include media-min-size(large) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    grid-template-areas:
      'blog1 blog2'
      'blog3 blog3';
  }

  > div {
    border-radius: 0.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 18.75rem;
    padding: 3rem;

    @include media-min-size(medium) {
      padding: 5rem;
      min-height: 29.6875rem;
    }

    @include media-min-size(large) {
      justify-content: center;
    }

    > div {
      color: #fff;
      max-width: 25rem;
    }
  }

  h3 {
    font-size: 2rem;
  }

  p {
    font-size: 1.125rem;
  }

  .blog1 {
    position: relative;
    overflow: hidden;
    grid-area: blog1;
  }
  .blog2 {
    position: relative;
    overflow: hidden;
    grid-area: blog2;
  }
  .blog3 {
    position: relative;
    overflow: hidden;
    grid-area: blog3;
    justify-content: flex-start;

    > div {
      max-width: none;
    }

    @include media-min-size(large) {
      h3 {
        font-size: 3rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }

  .blog--image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog--inner {
    position: relative;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "seeMore": "See more",
    "tellMeMore": "Tell me more",
    "blogSections": [
      {
        "title": "First time renting?",
        "content": "Check out the RVezy blog to get more information!"
      },
      {
        "title": "Looking to buy an RV?",
        "content": "Access exclusive discounts on new and used RVs through RVezy's dealership network.",
        "buttonText": "Learn more"
      },
      {
        "title": "RV Owner?",
        "content": "List your RV for free today and earn up to $1,400 /week."
      }
    ]
  },
  "fr": {
    "seeMore": "Voir plus",
    "tellMeMore": "Dites-moi en plus",
    "blogSections": [
      {
        "title": "Première location?",
        "content": "Consultez notre guide pour la première fois en VR pour vous aider à planifier votre voyage!"
      },
      {
        "title": "Vous pensez acheter un VR?",
        "content": "Obtenez les meilleurs prix avec nos partenaires.",
        "buttonText": "En savoir plus"
      },
      {
        "title": "Propriétaire de VR?",
        "content": "Affichez gratuitement votre VR sur RVezy et gagnez jusqu’à $1,400 /semaine."
      }
    ]
  }
}
</i18n>
