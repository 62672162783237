<template>
  <div
    class="popular-reviews"
    :class="{ 'popular-reviews-alternative': alternativeCard }"
  >
    <Review
      v-for="review in translatedReviews"
      :key="review.Content"
      :position="t(review.meta.Position)"
      :review="review"
      content-detached
      avatar-size="lg"
      has-translated-content
    />
  </div>
</template>

<script setup lang="ts">
import type { RvReview } from '~/types/rv'
import reviewsJson from '~/assets/data/reviews.json'

const { t } = useI18n({
  useScope: 'local',
})

defineProps({
  alternativeCard: {
    type: Boolean,
    default: false,
  },
})

const reviews = useState('userReviews', () => [...reviewsJson].sort(() => Math.random() - 0.5).slice(0, 3))

const translatedReviews = computed(() => {
  return reviews.value.map((review) => {
    const { Position, ...rest } = review

    return {
      ...(rest as RvReview),
      Content: t(review.Content),
      meta: {
        Position,
      },
    }
  })
})
</script>

<style lang="scss" scoped>
.popular-reviews {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  align-items: flex-start;

  @include media-min-size(large) {
    grid-template-columns: repeat(3, 1fr);
  }

  :deep(.testimonial-card) {
    .zavatar {
      width: 6rem;
      height: 6rem;
    }
  }
}
.popular-reviews-alternative {
  column-gap: 0 !important;
  margin: 0 1rem 0 1rem;

  :deep(.testimonial-card) {
    background-color: $color-white;
    border: 1px solid getColor('primary-50');
    border-radius: 8px;
    padding: 2rem;
    max-width: 22rem;
    .content {
      max-width: 17rem;
    }

    @include media-max-size(large) {
      max-width: 42rem;
      .content {
        max-width: 37rem;
      }
    }
  }
}
</style>

<i18n src="~/locales/common/popular-reviews.json" lang="json" />
