<template>
  <div class="landing-page-banner">
    <img
      :src="image"
      alt=""
      :width="imageDimensions.width"
      :height="imageDimensions.height"
      class="landing-page-banner--image"
      decoding="async"
    >
    <ZContainer class="landing-page-banner--content">
      <slot />
    </ZContainer>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  image: string
}>()

const { isMobile } = useDevice()
const imageDimensions = computed(() => {
  return isMobile
    ? {
        width: 750,
        height: 1211,
      }
    : {
        width: 1600,
        height: 750,
      }
})
</script>
