<template>
  <div class="zmedia">
    <div
      v-if="$slots.aside"
      :class="['zmedia-aside', computedAsideClasses]"
    >
      <slot name="aside" />
    </div>
    <div
      :class="['zmedia-body', bodyClasses]"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MediaAlignmentVertical } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  verticalAlign?: MediaAlignmentVertical
  rightAlign?: boolean
  asideClasses?: string
  bodyClasses?: string
}>(), {
  verticalAlign: 'top',
  rightAlign: false,
  asideClasses: '',
  bodyClasses: '',
})

const computedAsideClasses = computed(() => [
  `zmedia-aside-valign-${props.verticalAlign}`,
  {
    'zmedia-aside-right': props.rightAlign,
  },
  props.asideClasses,
])
</script>
