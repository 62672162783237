<template>
  <div class="cta-section logo-title-container bg-primary-500">
    <ZContainer>
      <div class="icon-title-container">
        <fa
          class="icon"
          :icon="['fal', 'rv']"
        />
        <span class="title">
          <strong>{{ t('strongTitle') }}</strong>
          {{ t('regularTitle') }}
        </span>
      </div>
      <ButtonBuyAnRv
        :text-btn="t('button')"
        cta="home-banner"
        use-highlight
      />
    </ZContainer>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
})
</script>

<style lang="scss" scoped>
.cta-section {
  color: #fff;
  padding: 1.25rem 0;
  text-align: start;

  @include media-min-size(small) {
    font-size: 1.19rem;
  }

  @include media-max-size(small) {
    font-size: 1rem;
  }

  .zcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .icon-title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    @include media-min-size(small) {
      align-items: center;
      justify-content: center;
    }

    @include media-max-size(large) {
      margin-bottom: 0.625rem;
    }

    img {
      max-height: 2.39rem;
      @include media-max-size(medium) {
        max-height: 1.81rem;
      }
    }

    .icon {
      width: 2.813rem;
      height: 2.813rem;
    }

    .title {
      display: inline-block;
      padding-left: 1.25rem;

      strong {
        display: inline-block;
      }

      @include media-max-size(small) {
        &:not(.inline-title) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
        }
      }
    }
  }

  .zbtn,
  .buy-rv-btn {
    width: auto;
    text-align: center;

    @include media-min-size(large) {
      margin-left: 2.813rem;
    }

    @include media-max-size(large) {
      justify-content: center;
      width: 100%;
    }
  }
}
.logo-title-container {
  .zcontainer {
    @include media-max-size(medium) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "strongTitle": "Looking to buy an RV?",
    "regularTitle": " Get exclusive discounts with RVezy partners",
    "button": "Buy an RV"
  },
  "fr": {
    "strongTitle": "Vous pensez acheter un VR?",
    "regularTitle": " Obtenez les meilleurs prix avec nos partenaires.",
    "button": "Acheter un VR"
  }
}
</i18n>
